@font-face {
  font-family: "proxima_reg";
  src: url("../assets/fonts/ProximaNova_Regular.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_light";
  src: url("../assets/fonts/Proxima_Nova_Alt_Light.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Cormorant_bold_italic";
  src: url("../assets/fonts/Cormorant_BoldItalic.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Cormorant_semi_bold_italic";
  src: url("../assets/fonts/Cormorant-SemiBoldItalic.otf");
  src: format("opentype");
}

::placeholder {
  color: "#EFEFEF";
  /* opacity: 0.5; */
}

.form_item {
  text-align-last: center;
  /* width: 592px; */
  height: "60px";
  font-family: "proxima_light";
  border: 2px #efefef solid;
  background-color: #efefef;
  border-radius: 4px;
}

.form_item:focus {
  box-shadow: none !important;
}

.form_item select:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none !important;
}
.form_item input:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none !important;
}

.form_submit_button {
  width: 200px;
  margin-top: 40px;
  height: 58px;
  font-family: "Cormorant_semi_bold_italic";
  font-size: 24px;
  letter-spacing: 2.5px;
  background-color: black;
  color: white;
  border-radius: 4px;
}

.request_title_div {
  position: absolute;
  border: 13px white solid;
  border-bottom: none;
  margin-top: 99px;
  width: 453px;
  margin-left: calc(50% - 226px);
  height: 174px;
  align-self: center;
}

.request_title {
  text-align: center;
  font-family: "proxima_bold";
  font-size: 36px;
  color: white;
  margin-top: 65px;
  letter-spacing: 0.6px;
  /* margin-bottom: 0px; */
}

.form_input {
  height: 60px;
  border: 2px #efefef solid;
  text-align: center;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 60px;
  text-align: center;
}

.react-datepicker__input-container input {
  border: 2px #efefef solid;
  border-radius: 3px;
}

.res_title {
  font-family: "Cormorant_bold_italic";
  text-align: center;
  font-size: 36px;
  margin-top: 80px;
}

.res_toggle {
  font-family: "proxima_reg";
  border-radius: 0px;
  border-width: 4px;
  width: 128px;
  height: 34px;
}

.res_toggle:focus {
  box-shadow: none;
  outline: none;
}

.res_toggle::selection {
  border: none;
  box-shadow: none;
}

.form-group {
  margin-bottom: 10px;
}

.restaurant_name {
  font-family: "proxima_light";
}

.welcome {
  font-family: "Cormorant_semi_bold_italic";
}

.arrow_img {
  padding: 0px 15px;
}

@media screen and (max-width: 767px) {
  .form_item {
    width: 300px;
  }
  .request_title_div {
    width: 253px;
    margin-left: calc(50% - 126px);
  }
  .request_title {
    font-size: 24px;
  }
}
