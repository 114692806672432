/** @format */

@font-face {
  font-family: "proxima_reg";
  src: url("../assets/fonts/ProximaNova_Regular.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_bold";
  src: url("../assets/fonts/Proxima_Nova_Bold.otf");
  src: format("opentype");
}

.contact_details {
}

::placeholder {
  color: gray;
}

/* p {
  color: black;
} */
.AddNewButton:active {
  color: gray;
}

.dataLabel {
  color: black;
  font-family: proxima_reg;
  font-size: 18px;
  font-weight: 600;
  margin-right: 5px;
}

.dataValue {
  font-family: proxima_reg;
  color: black;
  font-size: 18px;
  margin-left: 5px;
}

#modalTitle{
    align-self:  center;
    color: black;
}